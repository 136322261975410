import { createRouter, createWebHistory } from 'vue-router'
import Servie from '../views/Service.vue'

const routes = [
  {
    path: '/',
    name: 'Service',
    component: Servie
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
