<template>
  <div class="row">
    <div :class="mobile ? 'col-12' : 'col-6'">
      <q-form
        class="q-gutter-xs"
        ref="eform"
        @validation-success="() => (validation = true)"
        @validation-error="() => (validation = false)"
      >
        <div
          v-for="(device, index) in store.readWriteState.formInstruments"
          :key="index"
        >
          <div v-for="(field, i) in device" :key="i">
            <q-btn
              v-if="i == 0 && index > 0"
              class="q-my-sm"
              style="margin-left: 90%"
              round
              icon="delete"
              size="sm"
              @click="store.methods.removeInstrument(index)"
              color="red"
            >
              <q-tooltip class="bg-red">Usuń poniższe urządzenie</q-tooltip>
            </q-btn>

            <q-toggle
              class="q-mt-sm"
              v-if="field.name === 'warranty'"
              v-model="field.value"
              :label="field.label"
            />

            <q-toggle
              v-else-if="field.name === 'review' && device[i + 1].value === ''"
              v-model="field.value"
              :label="field.label"
            />

            <q-input
              v-else-if="field.name === 'desc' && !device[i - 1].value"
              v-model="field.value"
              bg-color="white"
              rounded
              outlined
              :label="field.label"
              :rules="field.rules"
              autogrow
              :maxlength="field.maxlength"
              lazy-rules
            >
              <template v-slot:prepend>
                <q-icon :name="field.icon" />
              </template>
            </q-input>

            <q-input
              v-else-if="field.name !== 'desc' && field.name !== 'review'"
              v-model="field.value"
              @keyup="
                (v) => {
                  i === 0
                    ? (field.value = v.target.value.toUpperCase())
                    : (field.value = v.target.value);
                }
              "
              bg-color="white"
              rounded
              outlined
              :label="field.label"
              :rules="field.rules"
              autogrow
              :maxlength="field.maxlength"
              :mask="field.mask"
              lazy-rules
            >
              <template v-slot:prepend>
                <q-icon :name="field.icon" />
              </template>
            </q-input>
          </div>
        </div>

        <div>
          <q-btn
            round
            style="margin-left: 90%"
            class="q-mb-xs"
            size="md"
            @click="addDev"
            icon="add"
            color="primary"
          >
            <q-tooltip class="bg-blue">dodaj kolejne urządzenie</q-tooltip>
          </q-btn>
        </div>
      </q-form>
      <!-- <q-page-scroller
        position="bottom-left"
        :scroll-offset="150"
        :offset="[18, 18]"
      >
        <q-btn fab icon="keyboard_arrow_up" color="secondary" />
      </q-page-scroller> -->
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { useQuasar } from "quasar";

export default {
  setup() {
    const store = inject("store");
    const $q = useQuasar();
    const mobile = store.getters.isMobile($q.screen);
    const eform = ref(null);
    const maxDevs = 20;

    const showNotify = () => {
      $q.notify({
        message: `Przekroczono liczbę dodanych urządzeń! Maksymalna ilość: ${maxDevs}`,
        color: "negative",
      });
    };

    const addDev = () => {
      if (store.readWriteState.formInstruments.length > maxDevs) {
        showNotify();
      } else {
        const obj = [
          {
            name: "name",
            value: "",
            label: "Model urządzenia",
            maxlength: 20,
            mask: "",
            icon: "tv",
            rules: [(val) => (val && val.length > 2) || "Zbyt któtka nazwa!"],
          },
          {
            name: "sn",
            value: "",
            label: "Numer seryjny",
            maxlength: null,
            mask: "XXXXXXXXXXXXXXXXXXXX",
            icon: "view_headline",
            rules: [
              (val) => (val && val.length > 3) || "Zbyt krótki numer seryjny!",
            ],
          },
          {
            name: "review",
            value: false,
            label: "przegląd techniczy",
          },
          {
            name: "desc",
            value: "",
            label: "Opis usterki",
            maxlength: 512,
            mask: "",
            icon: "description",
            rules: [(val) => (val && val.length > 9) || "Zbyt ktrótki opis!"],
          },
          {
            name: "comments",
            value: "",
            label: "Uwagi",
            maxlength: 512,
            mask: "",
            icon: "description",
            rules: [],
          },
          {
            name: "warranty",
            value: false,
            label: "naprawa gwarancyjna",
          },
        ];
        store.methods.addInstrument(obj);
      }
    };

    if (store.readWriteState.formInstruments.length === 0) {
      addDev();
    }

    return { store, eform, mobile, addDev, validation: ref(false) };
  },
};
</script>