<template>
  <div class="q-my-lg row">
    <div :class="mobile ? 'col-12' : 'col-3'">
      <q-uploader ref="uploader"
        style="width: 100%"
        :url="url"
        label="Dołącz pliki"
        hide-upload-btn
        multiple
        batch
        color="secondary"
        no-thumbnails
        accept=".jpg, image/*"
        :max-files="maxFiles"
        :max-file-size="maxSize * 1024 * 1024"
        @rejected="onRejected"
        @added="store.methods.setUploadStat(true)"
        @finish="sendOrderEvent"
      />
    </div>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useQuasar } from "quasar";
export default {
  props: ["url"],
  setup(props, context) {
    const store = inject("store");
    const $q = useQuasar();
    const mobile = store.getters.isMobile($q.screen);
    const uploader = ref(null);
    const maxFiles = 3;
    const maxSize = 20;

    const sendOrderEvent = () => {
      context.emit('sendOrderEvent');
    };

    const upload = () => {
      uploader.value.upload();
    };

    function onRejected() {
      $q.notify({
        type: "negative",
        message: `Maksymalny rozmiar pliku: ${maxSize} MB, maksymalna ilość załączników: ${maxFiles}. Tylko zdjęcia!`,
      });
    }

    return { store, mobile, onRejected, maxFiles, maxSize, uploader, upload, sendOrderEvent };
  },
};
</script>

<style>
.q-uploade {
background-image: linear-gradient(to right, #027BE3, #9C27B0);
}
</style>