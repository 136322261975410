<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated>
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
        />
        <img
          src="./assets/logo-topcon-healthcare.svg"
          width="250"
          class="q-ml-md"
        />

        <!-- <q-toolbar-title> Topcon Service </q-toolbar-title> -->
      </q-toolbar>
    </q-header>

    <q-footer elevated style="height: 35px">
      <q-toolbar>
        <q-toolbar-title>
          <span style="margin-top: -5px" class="absolute-center text-overline"
            >© 2021 - Topcon Polska sp. z o.o.</span
          >
        </q-toolbar-title>
      </q-toolbar>
    </q-footer>

    <q-drawer v-model="leftDrawerOpen" show-if-above bordered class="bg-grey-2">
      <q-list>
        <q-item-label header>Topcon Service</q-item-label>

        <q-item clickable to="/">
          <q-item-section avatar>
            <q-icon name="view_list" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Serwis</q-item-label>
            <q-item-label caption>Zgłoszenie serwisowe</q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/contact">
          <q-item-section avatar>
            <q-icon name="contact_phone" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Kontakt</q-item-label>
            <q-item-label caption>Pracownicy serwisu</q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          clickable
          tag="a"
          target="_blank"
          href="https://www.topconhealthcare.eu/pl_PL/legal-information/klauzula-informacyjna-rodo"
        >
          <q-item-section avatar>
            <q-icon name="verified_user" />
          </q-item-section>
          <q-item-section>
            <q-item-label>RODO</q-item-label>
            <q-item-label caption>Klauzula informacyjna RODO</q-item-label>
          </q-item-section>
        </q-item>

        <q-item
          clickable
          tag="a"
          target="_blank"
          href="https://www.topconhealthcare.eu/pl_PL/privacy-policy"
        >
          <q-item-section avatar>
            <q-icon name="verified_user" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Ochrona danych</q-item-label>
            <q-item-label caption
              >Polityka ochrony danych osobowych</q-item-label
            >
          </q-item-section>
        </q-item>

        <q-item clickable tag="a" href="https://www.topconhealthcare.eu/pl_PL">
          <q-item-section avatar>
            <q-icon name="home" />
          </q-item-section>
          <q-item-section>
            <q-item-label>Home</q-item-label>
            <q-item-label caption>Powrót do strony gównej</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref, provide } from "vue";
import store from "@/store";

export default {
  name: "LayoutDefault",

  setup() {
    provide("store", store);

    return {
      leftDrawerOpen: ref(false),
    };
  },
};
</script>

<style>
.q-header,
.q-footer {
  background-image: linear-gradient(to right, #027be3, #9c27b0);
}
</style>