<template>
  <div class="row">
    <div :class="mobile ? 'col-12' : 'col-6'">
      <q-form class="q-gutter-xs" ref="cform" @validation-success="() => validation = true" @validation-error="() => validation = false">
        <q-input
          v-for="(field, i) in store.readWriteState.formCustomer"
          :key="i"
          v-model="field.value"
          @keyup="
            (v) => {
              i === 0
                ? (field.value = v.target.value.toUpperCase())
                : (field.value = v.target.value);
            }
          "
          bg-color="white"
          fill-mask
          :label="field.label"
          :rules="field.rules"
          :mask="field.mask"
          :maxlength="field.maxlength"
          rounded
          outlined
          lazy-rules
          :type="field.type"
        >
          <template v-slot:prepend>
            <q-icon :name="field.icon" />
          </template>
        </q-input>
      </q-form>
    </div>
  </div>
</template>

<script>
import { inject, ref } from "vue";
import { useQuasar } from "quasar";

export default {
  setup() {
    const store = inject("store");
    const $q = useQuasar();
    const mobile = store.getters.isMobile($q.screen)
    const cform = ref(null);
            
    return { store, mobile, cform, validation: ref(false) };
  },
};
</script>