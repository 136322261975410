<template>
  <div class="q-my-lg row">
    <div :class="mobile ? 'col-12' : 'col-6'">
      <q-table
        :title="store.readWriteState.formCustomer[0].value"
        :rows="rows"
        :columns="columns"
        row-key="name"
        hide-bottom
      />
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useQuasar } from "quasar";

export default {
  setup() {
    const store = inject("store");
    const $q = useQuasar();
    const mobile = store.getters.isMobile($q.screen);
    const summary = store.getters.summary();
    const rows = [];
    
    const updateRows = () => {
      summary.forEach(item => {
        rows.push({ name: `${item[0]}`, sn: `${item[1]}` });
      });
    };

    const columns = [
      {
        name: "name",
        align: "center",
        label: "Nazwa",
        field: "name",
        sortable: false,
      },
      {
        name: "sn",
        align: "center",
        label: "Numer seryjny",
        field: "sn",
        sortable: false,
      },
    ];

    updateRows()

    return {
      mobile,
      columns,
      rows,
      store,
    };
  },
};
</script>