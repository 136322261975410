import { reactive, readonly } from "vue";

const readOnlyState = reactive({
    upload: false,
    contacts: [
        { color: "bg-indigo-2", name: "Topcon Polska sp. z o.o.", position: "Serwis centralny", area: "Polska", phone: "+48 326 705 220", email: "service.tpl@topcon.com", address: "Warszawska 23, 47-470 Siewierz", URLaddress: "https://www.google.pl/maps/place/Warszawska+23,+42-470+Siewierz/@50.4690626,19.2265022,17z/data=!3m1!4b1!4m5!3m4!1s0x4717288fd3381d2d:0x9ad61d075ea01a8f!8m2!3d50.4690592!4d19.2286909" },
        { color: "bg-indigo-1", name: "Grzegorz Gajda", position: "Kierownik serwisu", area: "Polska", phone: "+48 695 659 999", email: "ggajda@topcon.com", address: "Warszawska 23, 47-470 Siewierz", URLaddress: "https://www.google.pl/maps/place/Warszawska+23,+42-470+Siewierz/@50.4690626,19.2265022,17z/data=!3m1!4b1!4m5!3m4!1s0x4717288fd3381d2d:0x9ad61d075ea01a8f!8m2!3d50.4690592!4d19.2286909" },
        { color: "bg-indigo-1", name: "Roksana Sikorska", position: "Asystentka serwisu", area: "Polska", phone: "+48 797 218 696", email: "rsikorska@topcon.com", address: "Warszawska 23, 47-470 Siewierz", URLaddress: "https://www.google.pl/maps/place/Warszawska+23,+42-470+Siewierz/@50.4690626,19.2265022,17z/data=!3m1!4b1!4m5!3m4!1s0x4717288fd3381d2d:0x9ad61d075ea01a8f!8m2!3d50.4690592!4d19.2286909" },
        { color: "bg-indigo-1", name: "Andrzej Adamski", position: "inż. serwisu", area: "Polska", phone: "+48 781 200 516", email: "aadamski@topcon.com", address: "Warszawska 23, 47-470 Siewierz", URLaddress: "https://www.google.pl/maps/place/Warszawska+23,+42-470+Siewierz/@50.4690626,19.2265022,17z/data=!3m1!4b1!4m5!3m4!1s0x4717288fd3381d2d:0x9ad61d075ea01a8f!8m2!3d50.4690592!4d19.2286909" },
        { color: "bg-indigo-1", name: "Marek Bansiak", position: "inż. serwisu", area: "Polska", phone: "+48 665 464 999", email: "mbanasiak@topcon.com", address: "Warszawska 23, 47-470 Siewierz", URLaddress: "https://www.google.pl/maps/place/Warszawska+23,+42-470+Siewierz/@50.4690626,19.2265022,17z/data=!3m1!4b1!4m5!3m4!1s0x4717288fd3381d2d:0x9ad61d075ea01a8f!8m2!3d50.4690592!4d19.2286909" },
        { color: "bg-indigo-1", name: "Marcin Hofman", position: "inż. serwisu", area: "Polska", phone: "+48 505 306 403", email: "mhofman@topcon.com", address: "Warszawska 23, 47-470 Siewierz", URLaddress: "https://www.google.pl/maps/place/Warszawska+23,+42-470+Siewierz/@50.4690626,19.2265022,17z/data=!3m1!4b1!4m5!3m4!1s0x4717288fd3381d2d:0x9ad61d075ea01a8f!8m2!3d50.4690592!4d19.2286909" },
        { color: "bg-blue-1", name: "Grzegorz Fischer", position: "inż. serwisu", area: "Polska", phone: "+48 505 306 386", email: "gfischer@topcon.com", address: "Toruńska 151, 85-880 Bydgoszcz", URLaddress: "https://www.google.pl/maps/place/Toru%C5%84ska+151,+85-880+Bydgoszcz/@53.1182489,18.0733853,17z/data=!3m1!4b1!4m5!3m4!1s0x4703143ff8b7d061:0x18e7e17c5409b1c2!8m2!3d53.1182457!4d18.075574" },
        { color: "bg-blue-1", name: "Grzegorz Niedźwiedzki", position: "inż. serwisu", area: "Polska", phone: "+48 669 282 999", email: "gniedzwiedzki@topcon.com", address: "Warszawa", URLaddress: "https://www.google.pl/maps/place/Warszawa/@52.2330653,20.9211124,11z/data=!3m1!4b1!4m5!3m4!1s0x471ecc669a869f01:0x72f0be2a88ead3fc!8m2!3d52.2296756!4d21.0122287?hl=pl" },
    ]
})

const readWriteState = reactive({
    formCustomer: [
        { name: "name", value: "", label: "Nazwa placówki", type: "text", mask: "", icon: "business", maxlength: 100, rules: [val => val && val.length > 2 || 'Zbyt krótka nazwa!'] },
        { name: "street", value: "", label: "Ulica, numer", type: "text", mask: "", icon: "map", maxlength: 100, rules: [val => val && val.length > 3 || 'Zbyt krótka nazwa ulicy!'] },
        { name: "zip", value: "", label: "Kod pocztowy", type: "tel", mask: "##-###", icon: "map", maxlength: "", rules: [val => val && !val.includes('_') || 'Zbyt krótki kod!'] },
        { name: "place", value: "", label: "Miejscowość", type: "text", mask: "", icon: "map", maxlength: 50, rules: [val => val && val.length > 2 || 'Zbyt krótki kod!'] },
        { name: "person", value: "", label: "Osoba zgłaszająca", type: "text", mask: "", icon: "person", maxlength: 50, rules: [val => val && personNameRegx.test(val) || 'Błąd w imieniu lub nazwisku!'] },
        { name: "phone", value: "", label: "nr telefonu", type: "tel", mask: "### ### ###", icon: "phone", maxlength: "", rules: [val => val && !val.includes('_') || 'Zbyt krótki nr telefonu!'] },
        { name: "email", value: "", label: "Adres e-mail", type: "email", mask: "", icon: "email", maxlength: 100, rules: [val => val && emailRegx.test(val) || 'Nieprawidłowy adres e-mail!'] },
    ],
    formInstruments: []
})

const emailRegx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
const personNameRegx = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;

const methods = {
    setUploadStat(val) {
        readOnlyState.upload = val;
    },
    addInstrument(obj) {
        readWriteState.formInstruments.push(obj);
    },
    removeInstrument(index) {
        readWriteState.formInstruments.splice(index, 1);
    }
}

const getters = {
    getCustomer() {
        return readWriteState.formCustomer.map(q => q.value);
    },
    isMobile(scr) {
        return scr.lt.md ? true : false;
    },
    summary() {
        return readWriteState.formInstruments.map(q => q.map(sq => sq.value))
    }
}

export default {
    readOnlyState: readonly(readOnlyState),
    readWriteState,
    methods,
    getters
};