<template>
  <div class="q-pa-md">
    <q-stepper v-model="step" vertical color="primary" animated>
      <q-step :name="1" title="Placówka" icon="business" :done="step > 1">
        <ServiceCustomer ref="customerForm" />
        <q-stepper-navigation>
          <q-btn
            class="q-px-lg"
            rounded
            @click="cForm"
            color="primary"
            label="Dalej"
          />
        </q-stepper-navigation>
      </q-step>

      <q-step :name="2" title="Urządzenie" icon="tv" :done="step > 2">
        <ServiceEqipment ref="instrumentForm" />
        <q-stepper-navigation>
          <q-btn
            class="q-mr-xl"
            outline
            rounded
            @click="step = 1"
            color="primary"
            label="Wstecz"
          />
          <q-btn
            class="q-px-lg"
            rounded
            @click="eForm"
            color="primary"
            label="Dalej"
          />
        </q-stepper-navigation>
      </q-step>

      <q-step :name="3" title="Podsumowanie" icon="view_list">
        <ServiceSummary />
        <ServiceUploader
          ref="uploader"
          :url="url"
          @sendOrderEvent="sendOrder"
        />

        <q-stepper-navigation>
          <q-btn
            class="q-mr-xl"
            outline
            rounded
            @click="step = 2"
            color="primary"
            label="Wstecz"
            :disable="sent"
          />
          <q-btn
            class="q-px-lg"
            rounded
            @click="send"
            color="accent"
            label="Wyślij"
            :disable="sent"
            :loading="sent"
          />
        </q-stepper-navigation>
      </q-step>
    </q-stepper>
  </div>
</template>

<script>
import { ref, inject } from "vue";
import { useQuasar } from "quasar";
import axios from "axios";
import { useReCaptcha } from "vue-recaptcha-v3";
import ServiceCustomer from "../components/ServiceCustomer.vue";
import ServiceEqipment from "../components/ServiceEqipment.vue";
import ServiceSummary from "../components/ServiceSummary.vue";
import ServiceUploader from "../components/ServiceUploader.vue";

export default {
  components: {
    ServiceCustomer,
    ServiceEqipment,
    ServiceSummary,
    ServiceUploader,
  },
  setup() {
    const store = inject("store");
    const $q = useQuasar();
    const uploader = ref(null);
    const step = ref(1);
    const sent = ref(false);
    const customerForm = ref(null);
    const instrumentForm = ref(null);
    const url = `${location.origin}/upload`;
    //const url = "http://localhost:5000/upload";
    const { recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      //this.$recaptchaInstance.hideBadge();
      await recaptchaLoaded();
    };

    const cForm = () => {
      customerForm.value.cform.submit();
      if (customerForm.value.validation) {
        step.value = 2;
      }
    };

    const eForm = () => {
      instrumentForm.value.eform.submit();
      if (instrumentForm.value.validation) {
        step.value = 3;
      }
    };

    const send = () => {
      if (store.readOnlyState.upload) {
        uploader.value.upload();
      } else {
        sendOrder();
      }
    };

    const sendOrder = () => {
      recaptcha();
      sent.value = true;
      const customer = store.getters.getCustomer();
      const equipment = store.getters.summary();

      const jsonData = {
        uuid: Date.now(),
        customer,
        equipment,
      };

      const headers = {
        "Content-Type": "application/json",
      };

      axios
        .post(url, JSON.stringify(jsonData), {
          headers,
        })
        .then((response) => {
          if (response.data.includes("OK")) {
            store.methods.setUploadStat(true);
            $q.notify({
              type: "positive",
              message: `Twoje zgłosznie zostało przyjęte do realizacji. Kopia zgłoszenia została wysłana na adres e-mail: ${store.readWriteState.formCustomer[6].value}`,
            });
            sent.value = false;
            setTimeout(() => {
              window.location.href = `${location.origin}/summary`;
            }, 7000);
          } else {
            $q.notify({
              type: "negative",
              message:
                "Wystąpił problem z serwerem pocztowym! " + response.data,
            });
            sent.value = false;
          }
        })
        .catch((error) => {
          $q.notify({
            type: "negative",
            message: "Upss, coś poszło nie tak! " + error.message,
          });
          sent.value = false;
        });
    };

    return {
      step,
      sent,
      customerForm,
      instrumentForm,
      cForm,
      eForm,
      sendOrder,
      send,
      uploader,
      url,
    };
  },
};
</script>

<style>
.grecaptcha-badge {
  /* margin-bottom: 35px; */
  top: 50px !important;
  bottom: inherit !important;
}
</style>
